export enum UserStaffMessageGroupTypes {
  ALL = 'all',
  PAID = 'paid',
  PAID_PLUS = 'paid_plus',
  PAID_NON_PLUS = 'paid_non_plus',
}
export enum UserStaffMessageLocations {
  TOP = 'top',
  MID = 'mid',
}

export const FOMO_QUANTITY_REMAINING = 200;

export enum FeatureType {
  STRING = 0,
  BOOLEAN = 1,
  NUMBER = 2,
}

export const PRICING_CELL_STYLE =
  'w-1/5 max-w-[1/5] border border-sundog p-4 text-center font-medium text-midnight';
